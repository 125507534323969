<template>
    <div class="box">
        <router-link
            v-if="$can('admin/money/free-create')"
            :to="{ path: '/free/create' }"
        >
            <el-button
                type="primary"
                size="medium"
            >
                新建免单用户
            </el-button>
        </router-link>
        <div class="box-toolbar">
            <div class="box-item">
                <el-input
                    placeholder="手机号码\用户名\ID"
                    v-model="content"
                    class="input-with-select"
                    clearable
                    @keyup.enter.native="searchGo"
                >
                </el-input>
            </div>
            <div class="box-item">
                <el-button
                    icon="el-icon-search"
                    circle
                    @click="searchGo()"
                ></el-button>
            </div>
        </div>
        <el-table
            border
            height="70vh"
            v-loading="loading"
            :stripe="true"
            :data="lists"
        >
            <el-table-column
                width="90"
                class-name="text-mono"
                prop="id"
                label="Id"
            >
            </el-table-column>
            <el-table-column
                prop="user.username"
                width="150"
                label="用户名称"
            >
            </el-table-column>
            <el-table-column
                prop="user.nickname"
                width="150"
                label="微信名称"
            >
            </el-table-column>
            <el-table-column
                prop="user.phone"
                width="150"
                label="电话号码"
            >
            </el-table-column>
            <el-table-column
                width="60"
                align="center"
                label="头像"
            >
                <template slot-scope="scope">
                    <bp-avatar :avatar="scope.row.user.avatar"></bp-avatar>
                </template>
            </el-table-column>

            <el-table-column
                width="60"
                prop="user.sex"
                label="性别"
            >
            </el-table-column>

            <el-table-column
                prop="store.bsName"
                label="所在店铺"
            >
            </el-table-column>

            <el-table-column
                align="center"
                width="179"
                class-name="text-mono"
                prop="user.member_type"
                label="会员类型"
            >
            </el-table-column>

            <el-table-column
                label="免单状态"
                width="100"
            >
                <template slot-scope="scope">
                    <el-button
                        plain
                        size='mini'
                        type="primary"
                        v-if="scope.row.status == 1"
                    >启用中</el-button>
                    <el-button
                        plain
                        size="mini"
                        type="danger"
                        v-if="scope.row.status == 2"
                    >已关闭</el-button>
                </template>
            </el-table-column>

            <el-table-column
            width="200"
                label="操作"
             fixed="right">

                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        @click="Remove(scope.row)"
                        style="margin-right:10px;"
                    >
                        切换免单权限
                    </el-button>
                    <el-dropdown trigger="click">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                        >
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="$can('admin/money/free-update')"
                                @click.native="handleModelEdit(scope.row)"
                            >
                                编辑
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="$can('admin/money/free-delete')"
                                @click.native="handleModelDelete(scope.row)"
                            >
                                删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <div class="bp-pages">
            <page
                :pageSize="limit"
                :count="count"
                @change="pageChange"
                @changeSize="changeSize"
            >
            </page>
        </div>
    </div>
</template>

<script>
import BpAvatar from '@admin/components/Avatar'
import MoneyService from '@admin/services/MoneyService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
import flatry from '@admin/utils/flatry'

export default {
  name: 'MoneyFree',
  components: { BpAvatar, Page },
  mixins: [pagination],
  data () {
    return {
      searchType: [
        {
          id: 0,
          name: '手机号码'
        },
        {
          id: 1,
          name: '用户名'
        }
      ],
      content: '',
      loading: true,
      function: function (param) {
        return MoneyService.allMd(param)
      },
      delete: function (param) {
        return MoneyService.deleteFree(param)
      }
    }
  },
  methods: {
    async searchGo () {
      this.page = 1
      this.loading = true

      let param = {
        limit: this.limit,
        page: this.page,
        content: this.content
      }
      this.where = { content: this.content }
      const data = await this.function(param)

      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false
    },
    // 取消免单权限
    async Remove (e) {
      const { data } = await flatry(MoneyService.status(e.id, e))

      if (data) {
        this.$message.success(data.msg)
        this.searchGo()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .diao{
//   min-height: 600px;
//   height: 600px;
// }
</style>
